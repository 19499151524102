import React, { useState, useEffect } from 'react';
import "./task.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

function Task() {
    const [greeting, setGreeting] = useState('');
    const [currentDate, setCurrentDate] = useState('');
    const [viewType, setViewType] = useState('task'); // 'code', 'grid', 'task'
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPath, setCurrentPath] = useState('system:/user/');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [creatingType, setCreatingType] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [creatingNote, setCreatingNote] = useState(false);
    const [tasks, setTasks] = useState([
        { name: "Task One", type: "task", dueDate: "2023-10-01", details: "Details for task one" },
        { name: "Task Two", type: "task", dueDate: "2023-10-15", details: "Details for task two" },
        { name: "Task Three", type: "task", dueDate: "2023-11-01", details: "Details for task three" }
    ]);
    const [files, setFiles] = useState([
        { name: "Projects/", type: "folder", lastAccessed: "2023 May 11", children: [
            { name: "Project1.doc", type: "file", lastAccessed: "2023 May 12" },
            { name: "Project2.doc", type: "file", lastAccessed: "2023 May 13" }
        ]},
        { name: "Portfolio/", type: "folder", lastAccessed: "2023 Jul 19", children: [
            { name: "Portfolio1.pdf", type: "file", lastAccessed: "2023 Jul 20" },
            { name: "Portfolio2.pdf", type: "file", lastAccessed: "2023 Jul 21" }
        ]},
        { name: "Misc/", type: "folder", lastAccessed: "2023 Mar 25" },
        { name: "CS50 AI/", type: "folder", lastAccessed: "2023 Feb 10" },
        { name: "package.json", type: "file", lastAccessed: "8 days ago" }
    ]);
    const [filteredFiles, setFilteredFiles] = useState(files);
    const [isAddingTask, setIsAddingTask] = useState(false);
    const [newTask, setNewTask] = useState({ name: '', dueDate: '', details: '' });
    const [selectedTask, setSelectedTask] = useState(null); // For viewing and editing a task

    useEffect(() => {
        const findFilesInPath = (path, fileList) => {
            const segments = path.split('/').filter(Boolean).slice(2);
            let currentFiles = fileList;
            for (const segment of segments) {
                const segmentWithSlash = segment.endsWith('/') ? segment : segment + '/';
                const found = currentFiles.find(file => file.name === segmentWithSlash);
                if (found && found.type === 'folder') {
                    currentFiles = found.children || [];
                } else {
                    break;
                }
            }
            return currentFiles.filter(file => file.name.toLowerCase().includes(searchQuery.toLowerCase()));
        };

        const newFilteredFiles = findFilesInPath(currentPath, files);
        setFilteredFiles(newFilteredFiles);
    }, [currentPath, files, searchQuery]);

    useEffect(() => {
        const hour = new Date().getHours();
        setGreeting(hour < 12 ? 'Good Morning' : hour < 18 ? 'Good Afternoon' : 'Good Evening');
        const now = new Date();
        setCurrentDate(`${now.toLocaleString('default', { month: 'long' })} ${now.getDate()}, ${now.getFullYear()}`);
    }, []);

    const navigateIntoFolder = (folderName) => {
        setCurrentPath(`${currentPath}${currentPath.endsWith('/') ? '' : '/'}${folderName}`);
        setSelectedIndex(0);
    };

    const handleFileClick = (file) => {
        if (file.type === 'folder') {
            navigateIntoFolder(file.name);
        } else if (file.type === 'file') {
            setViewType('note'); // Switch to note view when a file is clicked
        }
    };

    const handleDelete = () => {
        // Deleting logic based on currentPath and selectedIndex
    };

    const handleCreate = (type) => {
        const newDate = new Date().toLocaleDateString();
        const newItem = type === 'folder' ?
            { name: "New Folder/", type: "folder", lastAccessed: newDate, children: [] } :
            { name: "New Note", type: "file", lastAccessed: newDate };

        const pathParts = currentPath.split('/').filter(Boolean);
        if (pathParts.length > 1) {
            let updatedFiles = [...files];
            let parentFolder = updatedFiles;

            for (const part of pathParts) {
                let index = parentFolder.findIndex(f => f.name === part + '/');
                if (index !== -1 && parentFolder[index].type === 'folder') {
                    if (!parentFolder[index].children) {
                        parentFolder[index].children = [];
                    }
                    parentFolder = parentFolder[index].children;
                }
            }

            parentFolder.push(newItem);
            setFiles(updatedFiles);
        } else {
            setFiles(prevFiles => [...prevFiles, newItem]);
        }

        if (type === 'file') {
            setViewType('note');
        }
    };

    const handleCreateTask = () => {
        const newTaskData = {
            name: newTask.name,
            dueDate: newTask.dueDate,
            details: newTask.details,
        };
        setTasks([...tasks, newTaskData]);
        setIsAddingTask(false);
        setNewTask({ name: '', dueDate: '', details: '' });
    };

    const openAddTaskModal = () => {
        setIsAddingTask(true);
    };

    const openTaskDetailsModal = (task) => {
        setSelectedTask(task);
    };

    const handlePathBackspace = () => {
        let newPath = currentPath.replace(/\/[^/]+\/?$/, ''); // Removes the last folder from the path
        if (newPath === 'system:/user' || newPath === 'system:/user/') {
            setCurrentPath('system:/user/');
        } else {
            setCurrentPath(newPath);
        }
    };

    const handleTextareaFormatting = (e) => {
        const value = e.target.value;
        const lastTwoChars = value.slice(-2);

        // Simple check for a list format
        if (lastTwoChars === "* ") {
            e.target.value = value.slice(0, -2) + "• "; // Replaces "* " with a bullet
        }
    };

const handleKeyDown = (e) => {
    const searchInput = document.getElementById('search-input');

    if (e.key === 'Escape' && (isAddingTask || selectedTask)) {
        setIsAddingTask(false);
        setSelectedTask(null);
    } else if (e.key === 'Escape' && searchInput === document.activeElement) {
        e.preventDefault();
        searchInput.blur();
    }

    if (e.key === 'Backspace' && !searchQuery && currentPath !== 'system:/user/') {
        e.preventDefault(); // Prevent default backspace behavior
        // Handle backspace for navigating directories
        let newPath = currentPath.replace(/\/[^/]+\/?$/, ''); // Remove last directory or file
        if (newPath === 'system:/user' || newPath === 'system:/user/') {
            setCurrentPath('system:/user/');
        } else {
            setCurrentPath(newPath);
        }
    }
};


useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
}, [isAddingTask, selectedTask, currentPath]);

return (
    <div className="app-container">
        <div className="top-bar">
            <div className="left-content">
                <span className="greeting">{greeting}</span>
                <span className="statistics-date">{currentDate}</span>
            </div>
            <span className="settings-gear" onClick={() => alert('Settings Clicked')}>⚙️</span>
        </div>
        <div className="filter-buttons">
            <div className='left-buttons'>
                {viewType !== 'note' && (
                    <>
                        <button onClick={() => setViewType('task')} className={viewType === 'task' ? 'active' : ''}>
                            <i className="fas fa-tasks"></i>
                        </button>
                    </>
                )}
                {viewType !== 'note' && (
                    <>
                        <button onClick={() => setViewType('code')} className={viewType === 'code' ? 'active' : ''}>
                            <i className="fas fa-code"></i>
                        </button>
                        <button onClick={() => setViewType('grid')} className={viewType === 'grid' ? 'active' : ''}>
                            <i className="fas fa-th-large"></i>
                        </button>
                    </>
                )}
            </div>
            <div className='right-buttons'>
                {viewType === 'task' ? (
                    <>
                        <button onClick={openAddTaskModal}>Add Task</button>
                    </>
                ) : viewType === 'note' ? (
                    <button onClick={handleDelete} className="delete-button">Delete</button>
                ) : (
                    <>
                        <button onClick={() => handleCreate('folder')}>Add Folder</button>
                        <button onClick={() => handleCreate('file')}>Add Note</button>
                    </>
                )}
            </div>
        </div>
        {viewType !== 'note' && viewType !== 'task' && (
            <div className="search-bar">
                <span className="find-file">Find file:</span>
                <span className={`path ${selectedIndex >= 0 ? 'path-active' : ''}`}>{`~${currentPath}`}</span>
                <input
                    id="search-input"
                    type="text"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                />
            </div>
        )}
        {viewType === 'task' ? (
            <div className="file-system-viewer">
                {tasks.map((task, index) => (
                    <div
                        key={index}
                        className={`file-item task-item ${selectedIndex === index ? 'selected' : ''}`}
                        onClick={() => openTaskDetailsModal(task)}
                    >
                        <span className="file-name">{task.name}</span>
                        <span className="last-accessed">{task.dueDate}</span>
                    </div>
                ))}
            </div>
        ) : viewType === 'code' || viewType === 'grid' ? (
            <div className={viewType === 'code' ? "file-system-viewer" : "grid-view"}>
                {filteredFiles.map((file, index) => (
                    <div key={file.name + index}
                         className={`${viewType === 'code' ? 'file-item' : 'grid-item'} ${file.type} ${selectedIndex === index ? 'selected' : ''}`}
                         onClick={() => handleFileClick(file)}>
                        {viewType === 'code' ? (
                            <>
                                <span className="file-name">{file.name}</span>
                                <span className="last-accessed">{file.lastAccessed}</span>
                            </>
                        ) : (
                            <>
                                <i className={`fas ${file.type === 'folder' ? 'fa-folder' : 'fa-file'}`}></i>
                                <span className="file-name">{file.name}</span>
                            </>
                        )}
                    </div>
                ))}
            </div>
        ) : viewType === 'note' ? (
            <div className="note-view">
                <button className="back-button" onClick={() => setViewType('code')}>← Back</button>
                <input className="note-title" placeholder="Enter note title..." />
                <textarea
                    placeholder="Details..."
                    className="note-body"
                    onInput={handleTextareaFormatting}
                ></textarea>
            </div>
        ) : null}

        {/* Modal for adding task */}
        {isAddingTask && (
            <div className="modal-overlay">
                <div className="modal">
                    <input
                        className="modal-input"
                        type="text"
                        placeholder="Task Title"
                        value={newTask.name}
                        onChange={(e) => setNewTask({ ...newTask, name: e.target.value })}
                    />
                    <input
                        className="modal-input"
                        type="date"
                        placeholder="Due Date"
                        value={newTask.dueDate}
                        onChange={(e) => setNewTask({ ...newTask, dueDate: e.target.value })}
                    />
                    <textarea
                        className="modal-textarea"
                        placeholder="Details"
                        value={newTask.details}
                        onChange={(e) => setNewTask({ ...newTask, details: e.target.value })}
                    />
                    <button className="create-button" onClick={handleCreateTask}>Create Task</button>
                </div>
            </div>
        )}

        {/* Modal for viewing and editing a task */}
        {selectedTask && (
            <div className="modal-overlay">
                <div className="modal">
                    <input
                        className="modal-input"
                        type="text"
                        value={selectedTask.name}
                        onChange={(e) => setSelectedTask({ ...selectedTask, name: e.target.value })}
                    />
                    <input
                        className="modal-input"
                        type="date"
                        value={selectedTask.dueDate}
                        onChange={(e) => setSelectedTask({ ...selectedTask, dueDate: e.target.value })}
                    />
                    <textarea
                        className="modal-textarea"
                        value={selectedTask.details}
                        onChange={(e) => setSelectedTask({ ...selectedTask, details: e.target.value })}
                    />
                    <button className="create-button" onClick={() => setSelectedTask(null)}>Close</button>
                </div>
            </div>
        )}
    </div>
);
}

export default Task;
